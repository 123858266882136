import React from 'react';
import config from '../config/common';

const Image = ({
  path = '',
  ...props,
}) => {
  return <img src={`${config.cdn}/img/${path}`} {...props}/>;
};

export default Image;
