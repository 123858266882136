import request from "lib/request";
import { showError, showSuccess } from "../lib/commons";

export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FETCHING = "GET_USER_FETCHING";

export const GET_INFOS_ERROR = "GET_INFOS_ERROR";
export const GET_INFOS_SUCCESS = "GET_INFOS_SUCCESS";
export const GET_INFOS_FETCHING = "GET_INFOS_FETCHING";

export const GET_PACKAGES_ERROR = "GET_PACKAGES_ERROR";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FETCHING = "GET_PACKAGES_FETCHING";

export const DO_RELOAD_FC_ERROR = "DO_RELOAD_FC_ERROR";
export const DO_RELOAD_FC_SUCCESS = "DO_RELOAD_FC_SUCCESS";
export const DO_RELOAD_FC_LOADING = "DO_RELOAD_FC_LOADING";

export const DO_PURCHASE_ERROR = "DO_PURCHASE_ERROR";
export const DO_PURCHASE_SUCCESS = "DO_PURCHASE_SUCCESS";
export const DO_PURCHASE_LOADING = "DO_PURCHASE_LOADING";

export const DO_CLAIM_ERROR = "DO_CLAIM_ERROR";
export const DO_CLAIM_SUCCESS = "DO_CLAIM_SUCCESS";
export const DO_CLAIM_LOADING = "DO_CLAIM_LOADING";

// Get user
export const isGetUserFetching = () => {
  return {
    type: GET_USER_FETCHING,
  };
};

export const getUserSuccess = (response) => {
  return {
    type: GET_USER_SUCCESS,
    payload: response.payload,
  };
};

export const getUserError = (response) => {
  return {
    type: GET_USER_ERROR,
    payload: response.payload,
  };
};

export const getUser = (callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isGetUserFetching());
    request("api/user/get").then(function (response) {
      if (response.status == "successful") {
        dispatch(getUserSuccess(response));
        callback(response.payload);
      } else {
        dispatch(getUserError(response));
        fallback(response);
      }
    });
  };
};

// Get infos
export const isGetInfosFetching = () => {
  return {
    type: GET_INFOS_FETCHING,
  };
};

export const getInfosSuccess = (response) => {
  return {
    type: GET_INFOS_SUCCESS,
    payload: response.payload,
  };
};

export const getInfosError = (response) => {
  return {
    type: GET_INFOS_ERROR,
    payload: response.payload,
  };
};

export const getInfos = (callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isGetInfosFetching());
    request("api/reward/get_infos").then(function (response) {
      if (response.status == "successful") {
        dispatch(getInfosSuccess(response));
        callback(response.payload);
      } else {
        dispatch(getInfosError(response));
        fallback(response);
      }
    });
  };
};

// Do reload FC
export const isReloadingFc = () => {
  return {
    type: DO_RELOAD_FC_LOADING,
  };
};

export const doReloadFcSuccess = (response) => {
  return {
    type: DO_RELOAD_FC_SUCCESS,
    payload: response.payload,
  };
};

export const doReloadFcError = (response) => {
  return {
    type: DO_RELOAD_FC_ERROR,
    payload: response.payload,
  };
};

export const doReloadFc = () => {
  return (dispatch, getState) => {
    dispatch(isReloadingFc());
    request("api/user/reload_fc").then(function (response) {
      if (response.status == "successful") {
        dispatch(doReloadFcSuccess(response));
        showSuccess("reload_fc", null, () => {}, "Thông báo");
      } else {
        dispatch(doReloadFcError(response));
      }
    });
  };
};

// Do purchase
export const isPurchasing = () => {
  return {
    type: DO_PURCHASE_LOADING,
  };
};

export const doPurchaseSuccess = (response) => {
  return {
    type: DO_PURCHASE_SUCCESS,
    payload: response.payload,
  };
};

export const doPurchaseError = (response) => {
  return {
    type: DO_PURCHASE_ERROR,
    payload: response,
  };
};

export const doPurchase = (
  packageId,
  type,
  callback = () => {},
  fallback = () => {}
) => {
  return (dispatch, getState) => {
    dispatch(isPurchasing());
    request("api/package/purchase", "POST", {
      body: JSON.stringify({
        package_id: packageId,
        currency: type,
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        dispatch(doPurchaseSuccess(response));
        callback(response.payload);
      } else {
        showError(response?.error_code);
        dispatch(doPurchaseError(response));
        fallback(response);
      }
    });
  };
};

// Do claim
export const isClaiming = () => {
  return {
    type: DO_CLAIM_LOADING,
  };
};

export const doClaimSuccess = (response) => {
  return {
    type: DO_CLAIM_SUCCESS,
    payload: response.payload,
  };
};

export const doClaimError = (response) => {
  return {
    type: DO_CLAIM_ERROR,
    payload: response,
  };
};

export const doClaim = (rewardId, callback = () => {}, fallback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request("api/reward/claim", "POST", {
      body: JSON.stringify({
        user_reward_id: rewardId,
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        dispatch(doClaimSuccess(response));
        callback(response.payload);
      } else {
        showError(response?.error_code);
        dispatch(doClaimError(response));
        fallback(response);
      }
    });
  };
};
