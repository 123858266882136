import {
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GET_USER_FETCHING,
  GET_INFOS_ERROR,
  GET_INFOS_SUCCESS,
  GET_INFOS_FETCHING,
  DO_RELOAD_FC_ERROR,
  DO_RELOAD_FC_SUCCESS,
  DO_RELOAD_FC_LOADING,
  DO_PURCHASE_ERROR,
  DO_PURCHASE_SUCCESS,
  DO_PURCHASE_LOADING,
  DO_CLAIM_ERROR,
  DO_CLAIM_SUCCESS,
  DO_CLAIM_LOADING,
} from "./actions";

import * as dayjs from "dayjs";

const initialState = {
  loadingUser: false,
  loadingInfos: false,
  loadingReloadFc: false,
  loadingPurchase: false,
  loadingClaim: false,
  login: false,
  user: {},
  fc: null,
  mc: null,
  userRewards: [],
  userPackageIds: [],
  rewards: [],
  now: null,
  delta: null,
  clientTime: null,
  packages: [],
  histories: [],
};

export default function currentUserReducer(
  state = initialState,
  { type, payload } = action
) {
  switch (type) {
    // USER
    case GET_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        login: true,
        user: payload.user_infos,
        fc: payload.fc,
        mc: payload.mc,
        userRewards: payload.user_rewards,
        histories: payload.claim_history,
        userPackageIds: payload.package_ids,
      };
      break;
    case GET_USER_ERROR:
      return {
        ...state,
        loadingUser: false,
      };
      break;
    case GET_USER_FETCHING:
      return {
        ...state,
        loadingUser: true,
      };
      break;

    // INFOS
    case GET_INFOS_SUCCESS:
      const delta = dayjs(payload.now).diff(dayjs(), "second");
      let clientTime = dayjs().subtract(Math.abs(delta), "second");

      if (delta > 0) {
        clientTime = dayjs().add(Math.abs(delta), "second");
      }

      return {
        ...state,
        loadingInfos: false,
        now: payload.now,
        rewards: payload.reward_infos,
        packages: payload.package_infos,
        delta: delta,
        clientTime: clientTime.format("YYYY-MM-DD HH:mm:ss"),
      };
      break;
    case GET_INFOS_ERROR:
      return {
        ...state,
        loadingInfos: false,
      };
      break;
    case GET_INFOS_FETCHING:
      return {
        ...state,
        loadingInfos: true,
      };
      break;

    // RELOAD FC
    case DO_RELOAD_FC_SUCCESS:
      return {
        ...state,
        loadingReloadFc: false,
        fc: payload.fc,
        mc: payload.mc,
      };
      break;
    case DO_RELOAD_FC_ERROR:
      return {
        ...state,
        loadingReloadFc: false,
      };
      break;
    case DO_RELOAD_FC_LOADING:
      return {
        ...state,
        loadingReloadFc: true,
      };
      break;

    // PURCHASE
    case DO_PURCHASE_SUCCESS:
      return {
        ...state,
        loadingPurchase: false,
        fc: payload.fc,
        mc: payload.mc,
        userRewards: payload.user_rewards,
        userPackageIds: payload.package_ids,
        histories: payload.claim_history,
      };
      break;
    case DO_PURCHASE_ERROR:
      return {
        ...state,
        loadingPurchase: false,
      };
      break;
    case DO_PURCHASE_LOADING:
      return {
        ...state,
        loadingPurchase: true,
      };
      break;

    // CLAIM
    case DO_CLAIM_SUCCESS:
      return {
        ...state,
        loadingClaim: false,
        userRewards: payload.user_rewards,
        histories: payload.claim_history,
      };
      break;
    case DO_CLAIM_ERROR:
      return {
        ...state,
        loadingClaim: false,
      };
      break;
    case DO_CLAIM_LOADING:
      return {
        ...state,
        loadingClaim: true,
      };
      break;

    // DEFAULT
    default:
      return state;
  }
}
