import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUser } from '../../authentication/actions';
import Image from '../../components/Image';
import { isMobile, getDiffDate, getHtmlPrizes } from '../../lib/commons';
import Footer from '../Footer';
import config from '../../config/common';
import * as dayjs from 'dayjs';

const PageLayout = ({currentUser, location, children, check, getUser}) => {
  useEffect(() => {
    // getUser();
  }, []);

  const shopItems = useMemo(() => {
    if (!currentUser?.packages?.length || !currentUser?.now) {
      return [];
    }

    return currentUser?.packages?.map(p => {
      const rewardIndex = currentUser?.rewards?.findIndex(r => r?.package_id === p?.id && r?.reward_type === 1);
      const bonusIndex = currentUser?.rewards?.findIndex(r => r?.package_id === p?.id && r?.reward_type === 2);
      p.reward = (rewardIndex !== -1 ? currentUser?.rewards?.[rewardIndex] : null);
      p.bonus = (bonusIndex !== -1 ? currentUser?.rewards?.[bonusIndex] : null);
      p.html = getHtmlPrizes(p?.reward?.item_name, p?.reward?.description, p?.description);

      // Start time
      const startSeconds = getDiffDate(currentUser?.now, p?.purchase_start_time);

      if (startSeconds > 0) {
        p.startTime = dayjs(currentUser?.clientTime).add(startSeconds, 'second').format('YYYY-MM-DD HH:mm:ss');
      } else {
        p.startTime = dayjs(currentUser?.clientTime).subtract(Math.abs(startSeconds), 'second').format('YYYY-MM-DD HH:mm:ss');
      }

      // End time
      const endSeconds = getDiffDate(currentUser?.now, p?.purchase_end_time);

      if (endSeconds > 0) {
        p.endTime = dayjs(currentUser?.clientTime).add(endSeconds, 'second').format('YYYY-MM-DD HH:mm:ss');
      } else {
        p.endTime = dayjs(currentUser?.clientTime).subtract(Math.abs(endSeconds), 'second').format('YYYY-MM-DD HH:mm:ss');
      }

      return p;
    })?.filter(p => {
      let localTime = (currentUser?.delta > 0 ? dayjs().add(Math.abs(currentUser?.delta), 'second') : dayjs().subtract(Math.abs(currentUser?.delta), 'second'));
      return localTime.isAfter(dayjs(p?.startTime)) && localTime.isBefore(dayjs(p?.endTime));
    });
  }, [currentUser?.rewards, currentUser?.packages, currentUser?.now]);

  const isShowVideo = useMemo(() => {
    if(shopItems.length == 0) {
      return false
    } else {
      return true
    }
  }, [shopItems])

  return (
    <>
      <main id="main">
        {/* {!isMobile() ? ( */}
        {!isMobile() ? (
          isShowVideo && (
            shopItems.length > 2  ? (
            <video autoPlay muted loop className="background">
              <source src={`/videos/video2.webm`} type='video/webm;codecs="vp8, vorbis"'/>
            </video>
            ) : (
              <video autoPlay muted loop className="background">
                <source src={`/videos/video1.webm`} type='video/webm;codecs="vp8, vorbis"'/>
              </video>
            )
          )
        ) : (
          isShowVideo && (
            shopItems.length > 2 ? (
              <Image path="background4.jpg?ver=1.0" className="background"/>
            ) : (
              <Image path="background2.jpg?ver=1.0" className="background"/>
            )
          )
        )}
        {children}
      </main>
      <Footer/>
    </>
  );
};

const mapDispatchToProps = {
  getUser
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
