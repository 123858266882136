import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';

export function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export function showError(code, title = 'Thông báo') {
  let msg = '';

  switch (code) {
    case 'not_logged_in':
      window.location.href = '/user/login';
      return;
      // msg = 'Vui lòng đăng nhập để tham gia sự kiện';
      break;

    case 'error_time_invalid':
      msg = 'Thời gian không hợp lệ';
      break;

    case 'error_not_login_game_yet':
      msg = 'Bạn vui lòng đăng nhập vào game để có thể nhận quà ngày hôm nay.';
      break;

    case 'error_account_not_found':
      msg = 'Bạn chưa đăng ký tài khoản';
      break;

    case 'error_not_enough_money':
      msg = 'Bạn không có đủ FC để mua gói này';
      break;

    default:
      msg = 'Hiện không thể thực hiện, vui lòng thử lại sau';
      break;
  }

  return Swal.fire({
    title: `${title}`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
      </div>
    `,
    confirmButtonText: 'Xác nhận',
    showConfirmButton: true,
    showCancelButton: false,
    showCloseButton: false,
    closeButtonHtml: '<img src="/img/icon-close.png"/>',
    customClass: {
      popup: `popup-alert popup-error ${title.length ? '' : 'popup-no-title'}`
    },
  })
}

export function showSuccess(code, data = {}, onClose = () => {}, title = 'Chúc mừng bạn') {
  let msg = '';

  switch (code) {
    case 'receive_prize':
      msg = `Đã nhận được ${data?.name}`;
      break;

    case 'reload_fc':
      msg = `Bạn đã cập nhật FC thành công.`;
      break;

    default:
      msg = 'Thực hiện thao tác thành công!';
      break;
  }

  return Swal.fire({
    title: `${title}`,
    html: `
      ${msg ? `<div class="popup-alert__message">${msg}</div>` : ''}
      ${data?.image ? `<div class="popup-alert__image"><img src="${data?.image}"/></div>` : ''}
    `,
    confirmButtonText: 'Xác nhận',
    showConfirmButton: true,
    showCancelButton: false,
    showCloseButton: false,
    closeButtonHtml: '<img src="/img/icon-close.png"/>',
    customClass: {
      popup: `popup-alert popup-success ${title.length ? '' : 'popup-no-title'}`
    },
    onClose: () => {
      onClose();
    },
  })
}

export function showConfirm(code, data = {}, callback = () => {}) {
  let title = '';
  let msg = '';

  switch (code) {
    case 'purchase':
      title = 'Xác nhận mua';
      msg = `Bạn chắc chắn muốn mua<br/><strong>${data?.name}</strong>`;
      break;

    default:
      msg = 'Bạn có chắc chắn không?';
      break;
  }

  return Swal.fire({
    title: `${title}`,
    html: `<div class="popup-alert__message">${msg}</div>`,
    confirmButtonText: 'Có',
    showCancelButton: true,
    cancelButtonText: 'Không',
    showCloseButton: true,
    closeButtonHtml: '<img src="/img/icon-close.png"/>',
    target: 'body',
    reverseButtons: true,
    customClass: {
      popup: `popup-alert popup-confirm ${title.length ? '' : 'popup-no-title'}`
    },
  }).then(res => {
    if (res.value) {
      callback();
    }
  });
}

export function toObj(array, key) {
  var result = array.reduce(function(map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function isInGame() {
  const result = new URLSearchParams(location.search).get('ingame');
  return !!result;
};

export function isMobile() {
  if ($(window).width() < 1023) {
    return true;
  }

  return false;
};

export function getDateTime(dateTime, format = 'HH:mm DD/MM/YYYY') {
  return dayjs(dateTime).format(format);
};

export function getDiffDate(start, end, format = 'second') {
  const date1 = dayjs(end);
  const date2 = dayjs(start);
  return date1.diff(date2, format);
};

export function isCurrentAfter(date) {
  return dayjs().isAfter(dayjs(date));
};

export function isCurrentBefore(date) {
  return dayjs().isBefore(dayjs(date));
};

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function format2digits(number) {
  return number < 10 ? ('0' + number) : number;
};

export function checkEvent(started, loggedIn, needLogin = true) {
  if (started === 0) {
    showError('not_started_yet');
    return 0;
  }

  if (needLogin && !loggedIn) {
    showError('not_logged_in');
    return 1;
  }

  return 2;
}

export function ReplaceNewString(str, key, newStr) {
  if (!str) {
    return;
  }

  return str.split(key)[0] + newStr;
}

export function getArray(start, end) {
  return Array(end - start + 1).fill().map((_, i) => start + i);
}

export function getAroundNumbers(num = 0, range = 3, length = 10) {
  let res = [];

  const all = getArray(0, length - 1);
  const nums = all.filter(n => n >= (num - range) && n <= (num + range));
  const index = nums.indexOf(num);

  if (index === range) {
    const missing = (range - (nums.length - 1 - index));

    if (missing === 0) {
      res = [...nums];
    } else {
      const pieces = all.slice(0, missing);
      res = [...nums, ...pieces];
    }
  } else if (index < range) {
    const missing = (range - index);
    const pieces = all.slice(missing * (-1));
    res = [...pieces, ...nums];
  }

  return res;
};

export function getHtmlPrizes(name, description, price) {
  let html = `
    <div class="prizes__name">${name}</div>
    <div class="prizes__price">${price}</div>
    <div class="prizes__message">
      Bạn sẽ nhận ngẫu nhiên các vật phẩm bên dưới
    </div>
  `;

  if (!!description) {
    const items = description?.split(`\n`)?.map(i => {
      return i?.split('|');
    });

    html += `<ul class="prizes__list">`;
      for (let i = 0; i < items?.length; i++) {
        html += `
          <li class="prizes__item">
            <img src="${items?.[i]?.[0] || ''}" class="prizes__item--image"/>
            <div class="prizes__item--name">${items?.[i]?.[1] || ''}</div>
          </li>
        `;
      }
    html += `</ul>`;
  }

  return html;
};
